import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup-terms',
    loadChildren: () => import('./login/signup-terms/signup-terms.module').then( m => m.SignupTermsPageModule)
  },
  {
    path: 'signup-complete',
    loadChildren: () => import('./login/signup-complete/signup-complete.module').then( m => m.SignupCompletePageModule)
  },

  // tab1 home part
  {
    path: 'home',
    loadChildren: () => import('./tab1-home/home/home.module').then( m => m.HomePageModule),
  },
  {
    path: 'letter',
    loadChildren: () => import('./tab1-home/letter/letter.module').then( m => m.LetterPageModule)
  },
  {
    path: 'price-information',
    loadChildren: () => import('./tab1-home/price-information/price-information.module').then( m => m.PriceInformationPageModule)
  },
  {
    path: 'guide',
    loadChildren: () => import('./tab1-home/guide/guide.module').then( m => m.GuidePageModule)
  },

  // tab2 order part
  {
    path: 'order-store-search',
    loadChildren: () => import('./tab2-order/order-store-search/order-store-search.module').then( m => m.OrderStoreSearchPageModule)
  },

  // tab3 orderlist part
  {
    path: 'orderlist',
    loadChildren: () => import('./tab3-orderlist/orderlist/orderlist.module').then( m => m.OrderlistPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./tab3-orderlist/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
  },

  // tab4 mypage part
  {
    path: 'mypage',
    loadChildren: () => import('./tab4-mypage/mypage/mypage.module').then( m => m.MypagePageModule)
  },

  // modal-dialogue part
  {
    path: 'delivery-address-detail-edit',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('./modal-dialogue/delivery-address-detail-edit/delivery-address-detail-edit.module').then( m => m.DeliveryAddressDetailEditPageModule)
  },

  // user part
  {
    path: 'signup-inputs',
    loadChildren: () => import('./login/signup-inputs/signup-inputs.module').then( m => m.SignupInputsPageModule)
  },
  {
    path: 'users-update-info',
    loadChildren: () => import('./tab4-mypage/users-update-info/users-update-info.module').then( m => m.UsersUpdateInfoPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./tab4-mypage/support/support.module').then(m => m.SupportPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./tab4-mypage/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'terms-service',
    loadChildren: () => import('./tab4-mypage/terms-service/terms-service.module').then( m => m.TermsServicePageModule)
  },
  {
    path: 'card-choose',
    loadChildren: () => import('./tab4-mypage/card-choose/card-choose.module').then( m => m.CardChoosePageModule)
  },
  {
    path: 'card-register',
    loadChildren: () => import('./tab4-mypage/card-register/card-register.module').then( m => m.CardRegisterPageModule)
  },
  {
    path: 'order-pay',
    loadChildren: () => import('./modal-dialogue/order-pay/order-pay.module').then( m => m.OrderPayPageModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./login/callback/callback.module').then( m => m.CallbackPageModule)
  },  {
    path: 'coupon',
    loadChildren: () => import('./tab4-mypage/coupon/coupon.module').then( m => m.CouponPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
