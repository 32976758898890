import { Component, enableProdMode } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';


// import { MessagingService } from './services/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  constructor(
    private platform: Platform,
    // private messagingService: MessagingService,

    private route: Router
  ) {
   // AngularFireModule.initializeApp(environment.firebase);
    this.initializeApp();
    // prod 에서 console.log 무시
    if (environment.production) {
      // enableProdMode();
      if (window){
        console.log = () => {};
      }
    }

    console.log('apiurl', environment.apiURL);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // firebase message 수신 처리 등록


      if (window.location.hash) {
        // Fragment exists
      } else {
        // Fragment doesn't exist
        if (!!!localStorage.getItem('userInfo')) {
          this.route.navigate(['login']);
        } else {
          this.route.navigate(['tabs/home']);
        }
      }
/*

*/
    });
  }


}
